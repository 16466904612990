.mainSectionSolar {
    width: 100vw;
    height: 500px;
    display: flex;
}


.ContainerMainSolar{
    width: 50%;
    height: 600px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ContainerMainSolar img {
    width: 40%;
    height: 50%;
    top: 0;
    left: 0;
}

.textEnergi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 200px;
}

@media screen and (max-width: 900px) {
    .mainSectionSolar {
        flex-direction: column;
        height: auto;
    }

    .ContainerMainSolar {
        width: 100%;
        height: 50vh;
    }

    .ContainerMainSolar img {
        height: 50%;
        width: auto;
        top: 0;
        left: 0;
    }

    .textEnergi {
        padding: 30px;
    }
}